var render = function () {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7, _vm$item8, _vm$item9, _vm$item10, _vm$item11, _vm$item12;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "text-primary"
  }, [_vm._v("Detail Subscription Offer")])]), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" General ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Name")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('strong', [_vm._v(_vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.name))])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Long Name")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_c('strong', [_vm._v(_vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.long_name))])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Offer Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getOfferType((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.offer_type_id)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Offer Status")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getOfferStatus((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.offer_status)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Free")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$item5 = _vm.item) !== null && _vm$item5 !== void 0 && _vm$item5.is_free ? _c('div', {
    staticClass: "badge badge-info"
  }, [_vm._v(" YES ")]) : _c('div', {
    staticClass: "badge badge-danger"
  }, [_vm._v(" NO ")])])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Web Price IDR")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.numberFormatIdr((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.price_idr)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Web Price USD")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.numberFormatUsd((_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.price_usd)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Point")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatNumber((_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : _vm$item8.price_point)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Status Active")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$item9 = _vm.item) !== null && _vm$item9 !== void 0 && _vm$item9.is_active ? _c('div', {
    staticClass: "badge badge-info"
  }, [_vm._v(" active ")]) : _c('div', {
    staticClass: "badge badge-danger"
  }, [_vm._v(" inactive ")])])]), _c('div', {
    staticClass: "form-group row font-italic"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("created")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$item10 = _vm.item) === null || _vm$item10 === void 0 ? void 0 : _vm$item10.created)) + " ")])]), _c('div', {
    staticClass: "form-group row font-italic"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("modified")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$item11 = _vm.item) === null || _vm$item11 === void 0 ? void 0 : _vm$item11.modified)) + " ")])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Discounts ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-sm-12 p-3"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headDiscount,
      "hover": "",
      "responsive": "xl",
      "items": _vm.items,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_c('router-link', {
          attrs: {
            "to": {
              name: _vm.DiscountDetailPath.name,
              params: {
                id: row.item.id
              }
            }
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(priceIdr)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getDiscountPrice(row.item.discount_rule, row.item.discount_idr, 'IDR')) + " ")];
      }
    }, {
      key: "cell(priceUsd)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getDiscountPrice(row.item.discount_rule, row.item.discount_usd, 'USD')) + " ")];
      }
    }, {
      key: "cell(pricePoint)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatNumber(row.item.discount_point)) + " ")];
      }
    }, {
      key: "cell(validFrom)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.valid_from)) + " ")];
      }
    }, {
      key: "cell(validTo)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.valid_to)) + " ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.created)) + " ")];
      }
    }])
  })], 1)])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Platforms ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-sm-12 p-3"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headPlatform,
      "hover": "",
      "responsive": "xl",
      "items": (_vm$item12 = _vm.item) === null || _vm$item12 === void 0 ? void 0 : _vm$item12.platform_offers,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(platform)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getPlatform(row.item.platform_id)) + " ")];
      }
    }, {
      key: "cell(priceIdr)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.numberFormatIdr(row.item.price_idr)) + " ")];
      }
    }, {
      key: "cell(priceUsd)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.numberFormatUsd(row.item.price_usd)) + " ")];
      }
    }, {
      key: "cell(pricePoint)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatNumber(row.item.price_point)) + " ")];
      }
    }, {
      key: "cell(tier)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.tier_code) + " ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.formatDate(row.item.created)) + " ")];
      }
    }])
  })], 1)])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }